@import "normalize";

// custom font
@font-face {
  font-family: "Spantaran";
  src: url("../static/fonts/Spantaran.ttf");
  font-weight: normal;
  font-style: normal;
}

html {
	scroll-behavior: smooth;
  font-family: "Bitter", serif;
  background-color: #000;
  color: #fffeee;
}

figure, section {
  padding: 0;
  margin: 0;
}

/**
 * Disable smooth scrolling when users have prefers-reduced-motion enabled
 */
@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

h2 {
  font-family: "Spantaran";
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #eee;
}

p {
  line-height: 1.8em;
}

#header {
  height: 100vh;
  background-image: url("../static/images/forest.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
}

#header h1 {
  text-indent: -100000px;
  background-image: url("../static/images/logo.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 85px;
  width: 190px;
}

@media screen and (min-width: 320px) {
  h2 {
    font-size: 2em;
  }

  #header nav ul.social {
    display: none;
  }

  #header {
    padding-top: 2em;
  }

  #header h1 {
    margin: 0 auto;
  }

  #header ul {
    justify-content: center;
  }

  #header li {
    padding: 1em 0.5em 0 0.5em;
  }
}

@media screen and (min-width: 1000px) {
  h2 {
    font-size: 3em;
  }

  #header {
    padding-top: 1.5em;
  }

  #header nav {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  #header nav ul.social {
    display: inherit;
  }

  #header nav :nth-child(1) { order: 2; }
  #header nav :nth-child(2) { order: 1; }
  #header nav :nth-child(3) { order: 3; }

  #header ul :nth-child(1) { order: 1; }
  #header ul :nth-child(2) { order: 2; }
  #header ul :nth-child(3) { order: 3; }
  #header ul :nth-child(4) { order: 4; }

  #header ul {
    width: 33vw;
    justify-content: left;
  }

  #header li {
    padding: 1em 1em 0 1em;
  }
}

#header nav .social img {
  height: 1.7rem;
}

#header nav .social .x img {
  height: 1.3rem;
  position: relative;
  top: 3px;
}

#header ul {
  list-style: none;
  display: flex;
  padding: 0 1em;
}

#header ul.social {
  justify-content: right;
}

#header li {
  margin: 0;
}

#header a {
  text-decoration: none;
  color: white;
  opacity: 0.9;
  font-family: "Spantaran";
  font-size: 1.2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header a:hover {
  opacity: 1;
}

#header .arrow {
  position: absolute;
  bottom: 0;
  width: 100vw;
  text-align: center;
}

#header .arrow img {
  width: 6em;
}

#header #mint a {
  font-family: "Bitter", serif;
}

#mint {
  text-align: center;
  position: absolute;
  top: 90%;
  left: 50%;
  width: 20em;
  margin-left: -10em;
  color: #ccc;
}

@media screen and (min-width: 320px) {
  #mint {
    height: 12em;
    margin-top: -10em;
  }
}

@media screen and (min-width: 1200px) {
  #mint {
    height: 20em;
    margin-top: -15em;
  }
}

#mint #connect {
  padding: 1em 1.5em;
  border: 2px solid #fff;
  color: #fff;
  text-decoration: none;
  border-radius: 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
  opacity: 0.85;
  letter-spacing: 1px;
}

#mint #connect.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

#mint p {
  padding-top: 1em;
  font-size: 0.9em;
}

#mint em {
  font-size: 1.2em;
  padding: 0 4px;
}

#mint #welcome {
  padding-bottom: 1em;
}

@media screen and (min-width: 320px) {
  #about {
    padding: 5em 5vw 5em 5vw;
    background: linear-gradient(185deg, #13131b 95%, #1b1e27 95%);
  }

  #about .hey img {
    max-width: 60vw;
  }

  #about .hey {
    text-align: center;
  }

  #about p.social {
    text-align: center;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  #about {
    padding: 5em 5vw 15em 5vw;
    display: flex;
    align-items: center;
    background: linear-gradient(185deg, #13131b 85%, #1b1e27 85%);
  }

  #about .hey img {
    max-width: 30vw;
  }

  #about p.social {
    text-align: left;
  }
}

#about {
  max-width: 90vw;
}

#about h1 {
  margin-bottom: 2em;
}

#about p {
  font-size: 1.1em;
  padding-bottom: 2em;
}

#about a:hover {
  opacity: 1;
}

#about .social img {
  max-width: 40px;
}

#about .social .x img {
  max-width: 30px;
  position: relative;
  top: -5px;
}

#about .social a {
  margin-right: 1em;
}

#about .social a {
  opacity: 0.8;
}

#about .social a:hover {
  opacity: 1;
}

@media screen and (min-width: 320px) {
  #genesis {
    padding: 0.1em 5vw 10em 5vw;
    background: linear-gradient(-3deg, #252833 1%, #1b1e27 1%);
  }

  #genesis section {
    display: block;
  }

  #genesis h2 {
    text-align: left;
  }

  .card {
    width: 90vw;
    height: 90vw;
    margin: 0 0 1vw 0;
  }

  .card img {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  #genesis {
    padding: 0.1em 5vw 15em 5vw;
    background: linear-gradient(-3deg, #252833 10%, #1b1e27 10%);
  }

  #genesis section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(85vw / 5), 1fr));
  }

  #genesis h2 {
    text-align: center;
  }

  .card {
    width: calc(85vw / 5);
    height: calc(85vw / 5);
    margin: 0 0 1vw 0;
  }

  .card img {
    width: calc(85vw / 5);
  }

  .card p {
    font-size: 0.8em;
  }
}

#genesis {
  max-width: 90vw;
}

#genesis > p {
  font-size: 1.1em;
  padding-bottom: 2em;
}

.card {
  background-color: transparent;
  perspective: 1000px;
  text-align: left;
}

.card .inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card:hover .inner {
  transform: rotateY(180deg);
}

.card .front, .card .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card .front {
  background-color: #bbb;
}

.card .back {
  background-color: #252733;
  color: white;
  transform: rotateY(180deg);
  background-image: url("../static/images/bg-pattern.png");
  overflow: scroll;
}

.card p, .card h3 {
  padding: 0 1em;
}

.card p {
  opacity: 0.8;
}

.card h3 {
  font-family: "Bitter", serif;
  letter-spacing: 1px;
  padding-left: 0.7em;
  font-weight: normal;
}

@media screen and (min-width: 320px) {
  #main {
    max-width: 90vw;
    padding: 2em 5vw 15em 5vw;
  }

  #main #preview {
    width: 90vw;
    height: 90vw;
  }

  #main h2 {
    text-align: left;
  }

  #main h3 {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 900px) {
  #main {
    max-width: 60vw;
    padding: 5em 20vw 15em 20vw;
  }

  #main div {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: row-reverse;
  }

  #main figure {
    width: 30vw;
  }

  #main #preview {
    width: 25vw;
    height: 25vw;
  }

  #main h2 {
    text-align: center;
  }

  #main h3 {
    font-size: 2em;
  }
}

#main {
  background: linear-gradient(2deg, #2f313f 10%, #252833 10%);
  text-align: center;
}

#main h3 {
  text-align: left;
  letter-spacing: 1px;
  font-weight: normal;
}

#main #preview {
  position: relative;
  border: 3px solid #000;
  border-radius: 10px;
  background: #2f313f;
  margin-top: 2em;
}

#main #preview img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#main p {
  text-align: left;
}

@media screen and (min-width: 320px) {
  #roadmap {
    max-width: 90vw;
    padding: 0.1em 5vw 5em 5vw;
    background: linear-gradient(-3deg, #393d4c 1%, #2f313f 1%);
  }

  #roadmap img {
    max-width: 50vw;
  }

  #roadmap figure {
    text-align: center;
  }

  #roadmap figure p, #roadmap figure h3 {
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  #roadmap {
    max-width: 60vw;
    padding: 5em 20vw 15em 20vw;
    background: linear-gradient(-3deg, #393d4c 5%, #2f313f 5%);
  }

  #roadmap figure {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #roadmap div {
    max-width: 25vw;
  }

  #roadmap figure {
    text-align: left;
  }

  #roadmap figure.even {
    text-align: right;
    flex-direction: row-reverse;
  }

  #roadmap figure.even p, #roadmap figure.even h3 {
    text-align: right;
  }

  #roadmap img {
    max-width: 25vw;
  }
}

#roadmap {
  text-align: center;
}

#roadmap figure {
  padding-bottom: 3em;
}

#roadmap h3.phase {
  position: relative;
  top: -30px;
  font-style: italic;
}

#roadmap h3 {
  font-family: "Bitter", serif;
  letter-spacing: 1px;
  font-size: 1.5em;
  margin-bottom: 0;
  font-weight: normal;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }
}

#roadmap img {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

#roadmap .even img {
  animation-duration: 5s;
}

#roadmap p {
  font-size: 1em;
  opacity: 0.8;
}

@media screen and (min-width: 320px) {
  #team {
    max-width: 90vw;
    padding: 5em 5vw;
    background: linear-gradient(2deg, #424759 1%, #393d4c 1%);
  }
}

@media screen and (min-width: 900px) {
  #team {
    max-width: 80vw;
    padding: 0.1em 10vw 5em 10vw;
    background: linear-gradient(2deg, #424759 10%, #393d4c 10%);
  }

  #team ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 auto;
    justify-content: center;
  }

  #team li {
    width: calc(70vw / 4);
  }
}

#team {
  text-align: center;
}

#team ul {
  list-style: none;
  padding: 3em 0 0 0;
}

#team li {
  padding-bottom: 3em;
  text-align: center;
}

#team figure {
  padding: 0;
  margin: 0;
}

#team h3 {
  font-size: 1.5em;
  font-family: "Bitter", serif;
  letter-spacing: 1px;
  font-weight: normal;
}

#team em {
  font-weight: normal;
  font-size: 1em;
  margin-top: -1em;
  display: block;
  font-family: "Bitter", serif;
}

#team .member img {
  width: 200px;
  clip-path: circle();
}

#team .member a img {
  width: 20px;
  opacity: 0.8;
}

#team .member a:hover img {
  opacity: 1;
}

#team .dev h3 {
  font-family: 'Courier New', Courier, monospace;
  font-weight: normal;
}

@media screen and (min-width: 320px) {
  #faq {
    max-width: 90vw;
    padding: 5em 5vw 10em 5vw;
    text-align: center;
  }

  #faq img {
    max-width: 70vw;
    max-height: 70vw;
    padding-top: 5em;
  }

  #faq figure {
    text-align: left;
  }

  #faq h2 {
    text-align: left;
  }
}

@media screen and (min-width: 900px) {
  #faq {
    max-width: 70vw;
    padding: 5em 15vw 10em 15vw;
    display: flex;
    text-align: left;
    flex-direction: row-reverse;
  }

  #faq>div {
    width: 45vw;
  }

  #faq img {
    max-width: 25vw;
    max-height: 25vw;
    padding-top: 10em;
  }
}

#faq {
  background: #424759;
}

#faq h3 {
  font-weight: 600;
  font-size: 1.2em;
  letter-spacing: 1px;
  position: relative;
  top: 5px;
  font-weight: normal;
}

#faq p {
  font-size: 1em;
  display: none;
  opacity: 0.8;
  margin-top: 0;
}

#faq .open p {
  display: block;
}

#faq figure div {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #2f313f;
}

#faq figure span {
  padding: 1.5em 0;
}

#faq .faq-item {
  padding: 0;
  margin: 0;
  cursor: pointer;
}

#faq a {
  color: #fffeee;
}

@media screen and (min-width: 320px) {
  footer {
    text-align: center;
  }
}

@media screen and (min-width: 640px) {
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

footer {
  background-color: #424759;
  padding: 0.1em 2em 0.5em 2em;
}

footer p {
  color: #fff;
  opacity: 0.7;
  padding-top: 0.2em;
  font-size: 0.8em;
}

footer img {
  height: 1.7rem;
}

footer .x img {
  height: 1.3rem;
  position: relative;
  top: 3px;
}

footer ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

footer li {
  padding: 0 1em;
}

footer a img {
  opacity: 0.8;
}

footer a:hover img {
  opacity: 1;
}

footer a {
  color: #fff;
}

#terms p {
  font-size: 1em;
}

#terms #logo {
  margin-bottom: 4em;
  display: block;
}

#terms #logo img {
  width: 220px;
}

#terms h1 {
  font-size: 2em;
}

#terms footer {
  background: #000;
  font-size: 0.8em;
}

#disclaimer h2 {
  font-family: "Bitter", serif;
  font-size: 1.6em;
  margin-top: 2em;
}

#disclaimer {
  max-width: 80vw;
  padding: 5em 10vw;
}